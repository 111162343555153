import io from "socket.io-client";

const BACK_IPS: any = {
  "https://api.tsaude.app/api/": "PRD",
  "http://34.151.235.109:5201/api/": "TEST",
  "http://35.215.251.183:5001/api/": "HML",
  "http://34.151.235.109:5101/api/": "HML2",
  default: "DEV",
};
interface CustomWindow extends Window {
  API_URL?: string;
  SOCKET_API_URL?: string;
  TCALLER_VERSION?: string;
}

const customWindow: CustomWindow = window;
const host = customWindow?.SOCKET_API_URL;

const socket = io(host || "", {});
export { socket, BACK_IPS };
